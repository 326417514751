export default [
    {
        name: 'Box 1',
        pokemons: [{id: 'b-1'},{id: 'b-2'},{id: 'b-3'},{id: 'b-4'},{id: 'b-5'},{id: 'b-6'},{id: 'b-7'},{id: 'b-8'},{id: 'b-9'},{id: 'b-10'},{id: 'b-11'},{id: 'b-12'},{id: 'b-13'},{id: 'b-14'},{id: 'b-15'},{id: 'b-16'},{id: 'b-17'},{id: 'b-18'},{id: 'b-19'},{id: 'b-20'},{id: 'b-21'},{id: 'b-22'},{id: 'b-23'},{id: 'b-24'},{id: 'b-25'},{id: 'b-26'},{id: 'b-27'},{id: 'b-28'},{id: 'b-29'},{id: 'b-30'},{id: 'b-31'},{id: 'b-32'},{id: 'b-33'},{id: 'b-34'},{id: 'b-35'},{id: 'b-36'},{id: 'b-37'},{id: 'b-38'},{id: 'b-39'},{id: 'b-40'},{id: 'b-41'},{id: 'b-42'},{id: 'b-43'},{id: 'b-44'},{id: 'b-45'},{id: 'b-46'},{id: 'b-47'},{id: 'b-48'},{id: 'b-49'},{id: 'b-50'}],
        img: "Volcano2.png"
    },
    {
        name: 'Box 2',
        pokemons: [{id: 'b-1'},{id: 'b-2'},{id: 'b-3'},{id: 'b-4'},{id: 'b-5'},{id: 'b-6'},{id: 'b-7'},{id: 'b-8'},{id: 'b-9'},{id: 'b-10'},{id: 'b-11'},{id: 'b-12'},{id: 'b-13'},{id: 'b-14'},{id: 'b-15'},{id: 'b-16'},{id: 'b-17'},{id: 'b-18'},{id: 'b-19'},{id: 'b-20'},{id: 'b-21'},{id: 'b-22'},{id: 'b-23'},{id: 'b-24'},{id: 'b-25'},{id: 'b-26'},{id: 'b-27'},{id: 'b-28'},{id: 'b-29'},{id: 'b-30'},{id: 'b-31'},{id: 'b-32'},{id: 'b-33'},{id: 'b-34'},{id: 'b-35'},{id: 'b-36'},{id: 'b-37'},{id: 'b-38'},{id: 'b-39'},{id: 'b-40'},{id: 'b-41'},{id: 'b-42'},{id: 'b-43'},{id: 'b-44'},{id: 'b-45'},{id: 'b-46'},{id: 'b-47'},{id: 'b-48'},{id: 'b-49'},{id: 'b-50'}],
        img: "Savanna.png"
    },
    {
        name: 'Box 3',
        pokemons: [{id: 'b-1'},{id: 'b-2'},{id: 'b-3'},{id: 'b-4'},{id: 'b-5'},{id: 'b-6'},{id: 'b-7'},{id: 'b-8'},{id: 'b-9'},{id: 'b-10'},{id: 'b-11'},{id: 'b-12'},{id: 'b-13'},{id: 'b-14'},{id: 'b-15'},{id: 'b-16'},{id: 'b-17'},{id: 'b-18'},{id: 'b-19'},{id: 'b-20'},{id: 'b-21'},{id: 'b-22'},{id: 'b-23'},{id: 'b-24'},{id: 'b-25'},{id: 'b-26'},{id: 'b-27'},{id: 'b-28'},{id: 'b-29'},{id: 'b-30'},{id: 'b-31'},{id: 'b-32'},{id: 'b-33'},{id: 'b-34'},{id: 'b-35'},{id: 'b-36'},{id: 'b-37'},{id: 'b-38'},{id: 'b-39'},{id: 'b-40'},{id: 'b-41'},{id: 'b-42'},{id: 'b-43'},{id: 'b-44'},{id: 'b-45'},{id: 'b-46'},{id: 'b-47'},{id: 'b-48'},{id: 'b-49'},{id: 'b-50'}],
        img: "Simple.png"
    },
    {
        name: 'Box 4',
        pokemons: [{id: 'b-1'},{id: 'b-2'},{id: 'b-3'},{id: 'b-4'},{id: 'b-5'},{id: 'b-6'},{id: 'b-7'},{id: 'b-8'},{id: 'b-9'},{id: 'b-10'},{id: 'b-11'},{id: 'b-12'},{id: 'b-13'},{id: 'b-14'},{id: 'b-15'},{id: 'b-16'},{id: 'b-17'},{id: 'b-18'},{id: 'b-19'},{id: 'b-20'},{id: 'b-21'},{id: 'b-22'},{id: 'b-23'},{id: 'b-24'},{id: 'b-25'},{id: 'b-26'},{id: 'b-27'},{id: 'b-28'},{id: 'b-29'},{id: 'b-30'},{id: 'b-31'},{id: 'b-32'},{id: 'b-33'},{id: 'b-34'},{id: 'b-35'},{id: 'b-36'},{id: 'b-37'},{id: 'b-38'},{id: 'b-39'},{id: 'b-40'},{id: 'b-41'},{id: 'b-42'},{id: 'b-43'},{id: 'b-44'},{id: 'b-45'},{id: 'b-46'},{id: 'b-47'},{id: 'b-48'},{id: 'b-49'},{id: 'b-50'}],
        img: "Simple.png"
    },
    {
        name: 'Box 5',
        pokemons: [{id: 'b-1'},{id: 'b-2'},{id: 'b-3'},{id: 'b-4'},{id: 'b-5'},{id: 'b-6'},{id: 'b-7'},{id: 'b-8'},{id: 'b-9'},{id: 'b-10'},{id: 'b-11'},{id: 'b-12'},{id: 'b-13'},{id: 'b-14'},{id: 'b-15'},{id: 'b-16'},{id: 'b-17'},{id: 'b-18'},{id: 'b-19'},{id: 'b-20'},{id: 'b-21'},{id: 'b-22'},{id: 'b-23'},{id: 'b-24'},{id: 'b-25'},{id: 'b-26'},{id: 'b-27'},{id: 'b-28'},{id: 'b-29'},{id: 'b-30'},{id: 'b-31'},{id: 'b-32'},{id: 'b-33'},{id: 'b-34'},{id: 'b-35'},{id: 'b-36'},{id: 'b-37'},{id: 'b-38'},{id: 'b-39'},{id: 'b-40'},{id: 'b-41'},{id: 'b-42'},{id: 'b-43'},{id: 'b-44'},{id: 'b-45'},{id: 'b-46'},{id: 'b-47'},{id: 'b-48'},{id: 'b-49'},{id: 'b-50'}],
        img: "Simple.png"
    },
]