import React from 'react'

function Online() {
    return (
        <span>
            <img  src="/images/icons/status_online.png" alt="" /><span>Online</span>
        </span>
    )
}

export default Online
