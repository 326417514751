export default[
    {
        button:"Visit Slot",
        img:"/slots.png"
    },
    {
         button:"Visit break the secret",
        img:"/kluis.png"
    },
    {
        button:"Visit who is this pokemon?",
        img:"/kluis.png"
    },
    {
        button:"Visit Wheel of Fortune",
        img:"/wheel-of-fortune.png"
    },
    {
        button:"Visit the Casino Shop",
        img:"/casino-store.png"
    },

]