export default[
    {
        description:`"Sorry, but I already have all the Pokémon I want!"`,
        img:"/Kayl.png"
    },
    {
        description:`"I can't negotiate with you now!"`,
        img:"/Wayne.png"
    },
    {
        description:`	"Sorry, I'm not looking to trade any Pokémon right now!"`,
        img:"/Remy.png"
    },
]