export const API_ENDPOINTS = {
    LOGIN: '/api/login.php',
    REGISTER: '/api/register.php',
    FORGOT_PASSWORD:'/api/forgot_password.php', // silver gold
    PLAYER_PROFILE:'/api/get-player-profile.php',
    FEATURED_PACKAGES:'/api/getFeaturedPackages.php',
    PACKAGES:'/api/getPackages.php',
    USER_INVOICE:'api/getUserInvoice.php',  
    GET_CHAT:'api/chat-api.php/messages',  
    GET_USERS:'api/getOnlineUsers.php',
    GET_FRIENDS:'api/getFriends.php',
    GET_FRIEND_REQUEST:'api/getFriendRequest.php',
    GET_PLAYERS:'api/getPlayers.php',
    SEND_CHAT:'api/chat-api.php/messages',
    GET_CHARACTERS:'api/getCharacters.php',
    SEND_FRIEND_REQUEST:'api/sendFriendRequest.php',
    ACCEPT_FRIEND_REQUEST:'api/acceptFriendRequest.php',
    DECLINE_FRIEND_REQUEST:'api/declineFriendRequest.php',
    BLOCK_FRIEND:'api/blockFriend.php',
    UNBLOCK_FRIEND:'api/unblockFriend.php',
    BLOCK_FRIEND_LIST:'api/getBlockFriendsList.php',
    GET_MAIL_LIST:'api/getMail',
    POKEMONS:'api/getPokemons.php',
    PLAYER_POKEMONS:'api/getPlayerPokemon.php',
    UPDATE_PLAYERS:'api/addNewPlayer.php',
    ADD_PLAYER_POKEMON:'api/addPlayerPokemon.php',
    NOTIFICATIONS:'api/getNotification.php',
    LOGOUT:'api/logout.php',
    REMOVE_FRIEND:'api/removeFriend.php',
    HOUSE_SELLER:'api/buyHouse.php',
    GET_HOUSE_SELLER:"api/getHouseSell.php",
    USER_BADGE:"api/getUserBadge.php",
    POKEMON_JUDGE:"api/PokemonJudge.php",
    POKEMON_GUID:"api/pokemonGuide.php",
    NOTIFICATION_COUNT:"api/getNotificationCount.php",
    POKEMON_BY_ID:'api/getPokemonProfileById.php',
    BEST_FISHER_OF_THE_DAY:'api/getBestFisherManOfDay.php',
    BEST_FISHER_OF_THE_YESTERDAY:'api/getBestFisherManOfYesterday.php',
    POKEMON_CALCULATOR_IVS:'api/PokemonCalculatorIvs.php',
    GOLD_MARKET:'api/goldMarket.php',
    START_FISHING:'api/startFishing.php',
    TRANSFER_VALUE:'api/transferValueToFriend.php',
    CREATE_MESSAGE:'api/createMessage.php',
    CHANGE_PERSONAL_DATA:'API/changePersonalData.php',
    UPDATE_PASSWORD:'api/changePassword.php',
    UPDATE_EMAIL:'api/changeEmail.php',
    RANK_LIST:'api/getRankList.php',
    UPDATE_PRESENTATION:'api/updatePresentation.php',
    UPDATE_HONORS:'api/Honars.php',
    GET_HONORS:"api/getHonors.php",
    ACCOUNT_SHARE:"api/shareAccount.php"
};