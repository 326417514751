import React from 'react'

function Offline() {
    return (
        <span>
            <img  src="/images/icons/status_offline.png" alt="" /><span>Offline</span>
        </span>
    )
}

export default Offline
